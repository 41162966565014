import './App.css';
import './styles/globals.css';
import React, { useState, useCallback, useRef } from 'react';
import { InputWithLabel } from './components/InputWithLabel.tsx';
import { Button } from './components/button.tsx';


function App() {
  const formRef = useRef(null);

  const searchCodeInformation = useCallback(async (event) => {
    event.preventDefault();

    const vehicleMakeModel = event.currentTarget.vehicleMakeModel!.value;
    const vehicleYear = event.currentTarget.vehicleYear!.value;
    const obdCode = event.currentTarget.obdCode!.value;

    console.log({ vehicleMakeModel, vehicleYear, obdCode });

    try {
      const queryParams = new URLSearchParams({ vehicleMakeModel, vehicleYear, obdCode })
      const url = `/search?${queryParams.toString()}`
      console.log(url)
      const res = await fetch(url);
      const data = await res.json();

      console.log({ data })
    } catch (e) {
      console.log((e as Error).message)
    }

  }, [])


  return (
    <div className="App">
      <div className="form-wrapper">
        <form ref={formRef} onSubmit={searchCodeInformation}>
          <InputWithLabel
            name={'vehicleMakeModel'}
            label={'Vehicle Make and Model'}
            placeholder={'Dodge Avenger'}
          />
          <InputWithLabel
            name={'vehicleYear'}
            label={'Vehicle Year'}
            placeholder={'2013'}
          />
          <InputWithLabel
            name={'obdCode'}
            label={'OBD Code'}
            placeholder={'P011B'}
          />
          <Button type="submit">Search</Button>
        </form>
      </div>
    </div>
  );
}

export default App;
