import React from "react"
import { Label } from "./label.tsx"
import { Input } from "./input.tsx"

export function InputWithLabel(props) {
  const { name, label, placeholder } = props;
  return (
    <div className="gap-y-3 w-full max-w-sm items-center gap-1.5">
      <Label htmlFor={name}>{label}</Label>
      <Input placeholder={placeholder} {...props} />
    </div>
  )
}